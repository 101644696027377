<template>

  <v-menu bottom left offset-y :disabled="!loggedInAndLive" style="z-index: 1000;">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-1" icon small color="accent" v-bind="attrs" v-on="on" >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item-group color="primary" >
 
        <AppMenuItem icon="mdi-home" :to="{name: 'Home'}" :title="$t('home')" />
        <AppMenuItem icon="mdi-pine-tree" :to="{name: 'Trees'}" :title="$t('trees')" v-if="trees" />
        <AppMenuItem icon="mdi-currency-usd-circle-outline" :to="{name: 'SkuIndex'}" :title="$t('skus')" v-if="skus" />
        <AppMenuItem icon="mdi-source-branch" :to="{name: 'About'}" :title="$t('about')" />

        <v-list-item  @click.stop="dialog = true" style="cursor: pointer">
          <v-icon color="primary" class="mr-4" dense>mdi-logout</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('logout')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>

    </v-list>

    <v-dialog
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="lighten-2 primary text-center">
          <v-row align-content="center">
            <v-col>Log out</v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
            Are you sure you want to log out
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Back
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="signOut"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-menu>

</template>

<script>
import AppMenuItem from '@/components/AppMenuItem';

export default {
  data: () => ({
    dialog: false
  }),
  components: {
    AppMenuItem,
  },
  computed: {
    loggedInAndLive() { return this.$store.getters.venueId!==null; },
    skus() { return (this.$store.getters.settings.skus || []).filter( e => e ).length>0; },
    trees() { return (((this.$store.getters.settings.settings || {})['app'] || {})['show'] || {})['trees']; },
  },
  methods: {
    signOut() {
      this.$store.dispatch('logout');
      this.$router.push({name: 'Home'});
    },
  },

};
</script>


<i18n>
{
  "en": {
    "home": "Home",
    "skus": "Prices",
    "about": "About",
    "logout": "Sign Out"
  },
  "es": {
    "home": "Hogar",
    "skus": "Precios",
    "about": "Acerca de",
    "logout": "Desconectar"
  },
  "sw": {
    "home": "Nyumbani",
    "skus": "Bei",
    "about": "Kuhusu",
    "logout": "Ondoka"
  }
}
</i18n>
