<template>
  <div>
    <v-card>
      <v-card-title>
        {{$t(title)}}
        <v-spacer />
        <v-btn v-if="showNew" class="ml-1" color="primary" small dense @click="getInputOrders">Reload</v-btn>
        <v-btn v-if="showNew" class="ml-1" color="primary" small dense :to="{name: 'NewInputOrder', query: { uuid: customer.uuid } }">{{$t('new')}}</v-btn>
      </v-card-title>

      <v-card-text>
        <div v-if="orders.length>0">
          <v-simple-table>
            <tbody>
              <tr v-for="order in orders">
                <td class="text-left"><Time :time="order.createdAt" format="date" /></td>
                <td class="text-left">{{order.description}}</td>
                <td class="text-right"><NumberFormatter :value="order.quantity" /></td>
                <td class="text-right">
                  <v-btn color="primary" text @click="confirmDeleteOrder(order)" v-if="order.status=='placed'" ><v-icon dense>mdi-delete</v-icon></v-btn>
                </td>
                <td class="text-right">
                  <v-btn color="primary" text @click="orderDelivered(order)"><v-icon dense>mdi-truck-delivery-outline</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div v-if="allOrders.length>orders.length">
            <v-btn text color="primary" @click="more" >{{$t('more')}}</v-btn>
          </div>
        </div>
        <div v-else>
          {{$t('noOrders')}}
        </div>
      </v-card-text>

    </v-card>

    <v-dialog
    v-model="deleteDialog"
    width="500"
    >
      <v-card>
        <v-card-title class="lighten-2 primary text-center">
          <v-row align-content="center">
            <v-col>Delete Order</v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
            Are you sure you want to delete this order
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteDialog = false"
          >
            Back
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteOrder(orderToDelete)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

  </div>
</template>


<script>
  import Time from '@/components/Time';
  import NumberFormatter from '@/components/NumberFormatter';
  import lookups from "@/mixins/lookups";

  export default {

    components: { Time, NumberFormatter },

    props: ['customer'],

    data: () => ({
      pageLength: 4,
      deleteDialog: false,
      orderToDelete: undefined
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      allOrders() { return this.$store.getters.ordersForCustomer(this.customerUuid).reverse(); },
      orders() { return this.allOrders.slice(0,this.pageLength); },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      title() { return ( this.partnerCode==='uzima_ug' ? 'Brooding Inputs' : 'title' ); },
      showNew() {
        let s = true;
        if ((this.partnerCode==='uzima_ug') && !(this.customer.isAnUzimaAgent)) {
          s = false;
        }
        return s;
      },
    },

    methods: {
      more() {
        this.pageLength = this.pageLength + 4;
        let _this = this;
        setTimeout(function() { _this.$redrawVueMasonry(); }, 300);
      },
      deleteOrder(order) {
        this.$store.dispatch('deleteOrder', order.uuid);
        this.deleteDialog = false
      },
      confirmDeleteOrder(order) {
        this.orderToDelete = order
        this.deleteDialog = true
        console.log("this is delete order dialog status: ", this.deleteDialog)
      },
      orderDelivered(order) {
        this.remoteLookup('updateOrderStatus', { customerUuid: this.customerUuid, uuid: order.uuid})
        .then( results => {
          if(results.status === 'delivered') {
            console.log("results 2: ", results.status)
            this.$store.dispatch('removeOrderFromCache', order.uuid);
          }
        })
      },
      async getInputOrders() {
        this.remoteLookup('getInputOrders', { customerUuid: this.customerUuid, catchedUuid: this.allOrders.map(e => e.uuid) })
        .then( results => {
          for (let io of results) {
            this.$store.dispatch('addOrderTocache', io)
          }
        })    
      }
    },

    mixins: [lookups],

  }
</script>


<i18n>
{
  "en": {
    "title": "Orders",
    "noOrders": "No orders."
  },
  "es": {
    "title": "Pedidos",
    "noOrders": "Sin ordenes."
  },
  "sw": {
    "title": "Mahitaji",
    "noOrders": "Hakuna maagizo."
  }
}
</i18n>

