<template>
  <v-app-bar dense app :color="colorCode">

    <v-app-bar-nav-icon class="hidden-sm-and-up white--text" @click="toHome" />
    <v-app-bar-nav-icon class="hidden-xs-only" @click="toHome">
      <Logo />
    </v-app-bar-nav-icon>
    <div  v-if="partnerCode==='apex_tz'" class="ml-1">
      <span class="white--text text-h5">eMazao</span>
    </div>
    <div  v-if="partnerCode==='care_tz'" class="ml-1">
      <span class="white--text text-h5">Care</span>
    </div>
    <v-spacer></v-spacer>

    <template v-if="live">
      <SearchBox :displaySearchOnRoutes="displaySearchOnRoutes" class="mr-2" />
      <HeaderBarRoutes />
    </template>

  </v-app-bar>

</template>

<script>
import SearchBox from '@/components/SearchBox';
import HeaderBarRoutes from '@/components/HeaderBarRoutes';
import Logo from '@/components/HeaderBarLogo';


export default {
  components: { SearchBox, HeaderBarRoutes, Logo },
  props: ['displaySearchOnRoutes'],
  computed: {
    live() { return this.$store.getters.authenticated; },
    colorCode() { 
      let color = "info";
      const code = this.$store.getters.settings.partnerCode;
        if(code == "nile_ug"){
          color = "primary";
        } else if (code=='ultimate_tz' || code=='two_tz') {
          color = 'rgba(0,79,160,0.1)';
        }
        return color;
      },
      
      partnerCode() { return this.$store.getters.settings.partnerCode; },
  },
  methods: {
    logout() { this.$store.dispatch('logout'); },
    toHome() {
      if (this.$route.name==='Home') {
        this.$router.replace({ name: "Home", query: { f: 'h'} })
      } else {
        this.$router.push({ name: 'Home' });
      }
    },
  }
};
</script>

